import { Grid, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import Solar from '../../assets/solar.jpg'

const FirstContainer = styled(Stack)(({theme}) => ({
  paddingLeft: 50,
  paddingRight: 50,
  paddingTop: 24,
  paddingBottom: 24,
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    padding: '1rem 2rem'
  }
}));

const SecondContainer = styled(Stack)(({theme}) => ({
  width: '80%',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}))

const InnerSection = styled('div')(({theme}) => ({
  marginTop: 5,
  width: '70%',
  textAlign: 'justify',
  [theme.breakpoints.down('md')]: {
    width: '90%'
  }
}))

const ImageContainer = styled(Stack)(({theme}) => ({
  width: '100%',
  height: 400,
  display: 'flex',
  justifyContent: 'center',
  // alignItems: 'center',
  overflow: 'hidden',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}))

const PrincipleImage = styled('img')(({theme}) => ({
  height: '100%',
  width: '100%',
  objectFit: 'contain'
}))

const SolarText = styled(Typography)(({theme}) => ({
  paddingLeft: 130,
  [theme.breakpoints.down('md')]: {
    paddingLeft: 10,
    marginBottom: '-2rem'
  }
}))

const SolarDescription = styled(Typography)(({theme}) => ({
  paddingLeft: 130,
  [theme.breakpoints.down('md')]: {
    paddingLeft: 10,
    marginBottom: '-2rem'
  }
}))

function HousingFeatures() {
  return (
    <>
    <FirstContainer>
      <SecondContainer>
      <Typography variant="h4" color="primary" marginBottom={2}>Housing Features & Advantages</Typography>
      <Typography variant="h5" color="initial">Thermal Insulation</Typography>
      <InnerSection>
        <Typography variant="body1" color="initial">The advanced exterior overall thermal insulation meets international energy—saving standards for buildings. It prevents the cold and thermal bridges phenomenon, and keeps buildings away from damages such as dampness, distortion, mildew and corrosion.</Typography>
        <Typography variant="body1" color="initial">The unique heat reflection and ventilation layer design can make better insulation effects.Temperature can be reduced by five to eight degrees. </Typography>
      </InnerSection>
      </SecondContainer>
    </FirstContainer>

    <FirstContainer>
      <SecondContainer>
      <Typography variant="h5" color="initial">Seismic & Wind Resistance</Typography>
      <InnerSection>
        <Typography variant="body1" color="initial">The building can withstand the shock of 9 on the ritcher scale.The dense light gauge steel and column are connected in flexible method with screws and bolts, which can absorb the energy of earthquake. There are fittings between foundation and wall, which are resistant to pull out and shear. The total weight of the house is light apace, which is only 1/6 of traditional concrete houses. Therefore it has better anti-seismic performance. </Typography>
        <Typography variant="body1" color="initial">There are special fittings among roof, floor,wall and basement.The strong connection can help resist typhoon of 12 levels. </Typography>
      </InnerSection>
      </SecondContainer>
    </FirstContainer>

    <FirstContainer>
      <SecondContainer>
        <Typography variant="h5" color="initial">Roof Load-Bearing & Fireproof</Typography>
        <InnerSection>
          <Typography variant="body1" color="initial">The roof can withstand 1500mm thickness snow. The structure can be designed according to climate requirements.</Typography>
          <Typography variant="body1" color="initial">Low-rise building fire resistance meet 2.5h (Grade II) design standard. High-rise building fire resistance meets 3hr (Grade I) design standard. Wall and floor slab use special technology which can meet 3h refactory limit.</Typography>

        </InnerSection>
      </SecondContainer>
    </FirstContainer>

    <FirstContainer>
      <SecondContainer>
        <Typography variant="h5" color="initial">Sound Insulation</Typography>
        <InnerSection>
          <Typography variant="body1" color="initial">Improved technology on sound insulation and shock absorption meet international building standards for sound insulation. Special methods are performed, especially towards the audio frequency ranging from 250 to 1000 Hz which is the most sensitive to ears, in order to create a quiet and comfortable living environment. </Typography>
        </InnerSection>
      </SecondContainer>
    </FirstContainer>

    <FirstContainer>
      <SecondContainer>
        <Typography variant="h5" color="initial">Moisture-Proof & Ventilation</Typography>
        <InnerSection>
          <Typography variant="body1" color="initial">There is a gap between wall and roof truss, so the air can flow inside. </Typography>
          <Typography variant="body1" color="initial">There is a one-way ventilation layer in the composite wall, which is able to make the wall 'breathing'. This means moisture can be reduced inside of the door. </Typography>
        </InnerSection>
      </SecondContainer>
    </FirstContainer>

    <FirstContainer>
      <SecondContainer>
        <Typography variant="h5" color="initial">Durable</Typography>
        <InnerSection>
          <Typography variant="body1" color="initial">Ninety years structure safety guarantee. Special coating technology enables the structural materials to have the self-restoration function to prevent rust and corrosion. Cladding comprises new light weight building materials that are fire resistant, anti-corrosion and anti-moth. </Typography>
        </InnerSection>
      </SecondContainer>
    </FirstContainer>

    <FirstContainer>
      <SecondContainer>
        <Typography variant="h5" color="initial">Energy Efficient & Environmental Protection</Typography>
        <InnerSection>
          <Typography variant="body1" color="initial" marginBottom={1}><span>Energy Saving: </span> 65%-90% energy reduction.</Typography>
          <Typography variant="body1" color="initial" marginBottom={1}><span>Water Saving: </span> The dry construction consumes 10% of water used in traditional construction.</Typography>
          <Typography variant="body1" color="initial" marginBottom={1}><span>Material Saving: </span> 90% of materials used are recycled.</Typography>
          <Typography variant="body1" color="initial" marginBottom={1}><span>Land Saving: </span> The usable floor area increases by 10%.</Typography>
          <Typography variant="body1" color="initial" marginBottom={1}><span>Environment Protection: </span> Pollution-free construction system.</Typography>
          <Typography variant="body1" color="initial">Research shows that based on modern technology, 20 million square meters of housing and buildings can save 4 hundred thousand tons of steel, 4 million tons of cement, and 8 hundred thousand tons of water and reduce 172 thousand tons of CO<sub>2</sub> emissions.</Typography>
        </InnerSection>
      </SecondContainer>
    </FirstContainer>

    <FirstContainer>
      {/* <InnerSection> */}
     <Grid container>
      <Grid direction='row' item xs={12} md={6} lg={6}>
            <SolarText variant="h6" color="initial">Solar Power (Optional)</SolarText>
          <SolarDescription variant="body1" color="initial" marginTop={5}>There is an option to install solar power system as the only power source or as a backup to the national grid. Our company will supply and install the entire system consisting of solar panels / photovoltaics, batteries and associated electrical appurtenances.</SolarDescription>
        <ImageContainer>
          <PrincipleImage src={Solar} />
        </ImageContainer> 
      </Grid>
     </Grid>
      {/* </InnerSection> */}
    </FirstContainer>
    </>
  )
}

export default HousingFeatures