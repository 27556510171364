import React from 'react';
import classes from './Footer.module.css';
import { BsInstagram } from 'react-icons/bs';
import { BsFacebook } from 'react-icons/bs';
import { FaXTwitter } from 'react-icons/fa6';
import Prefabs from '../../assets/RADprefabs14.jpg'
import Logo from '../../assets/Logo1.jpg';
import ContactsForm from '../Form/ContactsForm';

function Footer() {
  return (
    
    <div className={classes['div-container']}>
    <div className={classes.tech}>
      <div className={classes.left}>
        {/* <img src={Prefabs} alt="house" /> */}
        <ContactsForm />
      </div>
      <div className={classes.right}>
        <h3>Contact Us</h3>
       <div className={classes.location}>
        <p>GA Insurance House, 2nd Floor</p>
        <p>Ralph Bunche Road</p>
        <p>P.O. Box 11106-00100</p>
        <p>Nairobi, Kenya</p>
       </div>
       <div className={classes.contacts}>
        <p> <span style={{fontWeight: 'bold'}}>Phone:</span> +(254)792 922305</p>
        <section className={classes.app}>
          <span style={{fontWeight: 'bold'}}>WhatsApp:</span>
          <div>
            <p>+254792922305</p>
            <p>+254722701603</p>
            {/* <p>+16146223270</p> */}
          </div>
        </section>
        <p><span style={{fontWeight: 'bold'}}>Email:</span> info@Infraconsheltersafrica.com</p>
       </div>

       <div className={classes['working-hours']}>
        <div className={classes['business-hours']}>
            <h4>Business Hours</h4>
                <div>
                    <p>Monday to Friday</p>
                    <p>9:00 am to 6:00 pm</p>
                </div>
                <div>
                    <p>Saturday</p>
                    <p>9:00 am to 12 noon</p>
                </div>
                <div>
                    <p>Sunday </p>
                    <p>by appointment only.</p>
                </div>

        </div>
        <div>
            <div>
                <h4>Get Social</h4>
                <div className={classes.socials}>
                    <p><BsFacebook /></p>
                    <p><FaXTwitter /></p>
                    <p><BsInstagram /></p>
                </div>
                <div className={classes.logo}>
                    <img src={Logo} alt="Logo" />
                </div>
            </div>
        </div>
       </div>

      </div>
    </div>
    
  </div>
  )
}

export default Footer