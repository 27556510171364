import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React from 'react';


const ThemeProvider = ({ children }) => {

    // const fontFamily = "'Pixelify Sans', cursive";

    const theme = createTheme({
        palette: {
          primary: {
            main: '#05415b',
          },
          secondary: {
            main: '#228B22'
          }
        },
        typography: {
            // fontFamily
        },
        components: {
            MuiButton: {
                defaultProps: {
                    disableElevation: true
                },
                styleOverrides: {
                  root: () => ({
                    borderRadius: 100
                  })
                }
            }
        }
    });
  return (
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  )
}

export default ThemeProvider