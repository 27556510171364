import React from 'react';
import classes from './AboutUs.module.css';
import Prefabs from '../../assets/RADprefabs14.jpg';
import Button from '../../components/Button/Button';
import { Grid, Stack, Typography, styled } from '@mui/material';
import firstImage from '../../assets/R1.jpg';
import secondImage from '../../assets/R2.jpg';
import thirdImage from '../../assets/R3.jpg';

const PRINCIPLES = [
  { title: 'Reduce', content: 'Energy saving, water saving, material saving, land saving', image: firstImage },
  { title: 'Reuse', content: 'Most of the building materials can be reused in other projects', image: secondImage },
  { title: 'Recycle', content: '90% of the building materials can be recycled', image: thirdImage },
]

const StyledSecondContainer = styled(Stack)(({ theme }) => ({
  padding: 40,
  [theme.breakpoints.down('md')]: {
    padding: '50px 12px'
  }
}));

const PhilosophyContainer = styled('div')(({ theme }) => ({
  height: '100%',
  minHeight: 100,
  width: 10,
  backgroundColor: theme.palette.primary.main
}));

const PrincipleImageContainer = styled('div')(() => ({
  width: '100%',
  height: 300,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden'
}));

const PrincipleImage = styled('img')(() => ({
  height: '100%',
  width: '100%',
  objectFit: 'contain'
}))

function AboutUs() {
  return (
    <>
      <div className={classes.container}>
        <div className={classes['inner-container']}>
          <div className={classes.content}>
            <Typography variant="h4" sx={{color: 'white'}}>About Us</Typography>
            <Typography variant="h5" color="inherit" marginBottom={2}>Company Profile</Typography>
            <p className={classes.description}>      
            <p>Infracon Shelters Africa Limited, founded in 2018, is dedicated to the design, production, construction, promotion and servicing of modern, contemporary housing units. The company utilizes world-class light steel structure design software and precision equipment, so that components of integrated houses can be automatically made according to the customers' requirements and aspirations. Based on new technologies, we pay a great deal of attention to safety, construction period, comfort as well as energy saving and environmental protection, in order to ensure seamless delivery of impeccable product to our clients' satisfaction. </p> 
              <p>Infracon Shelters Africa Limited undertakes the construction of high quality residential villas, commercial office buildings, schools, government buildings, camp-sites and barracks. The modern houses we build have so many desirable features related to safety, comfort, short construction period, heat insulation, sound insulation, moisture protection, ventilation, durability, wind and earthquake resistance, fire prevention as well as environmental protection. </p>
              <p>Our business strategy is to provide safe and comfortable houses based on advanced technology, good materials and short construction period, which in turn enables us to gain foothold and broaden our market share. Not only do we build quality houses, but we also foster trust and long-term relationship with our clients.</p>
              <p>Our people are innovative and conscious about the environment and sustainability. We are commited to deliver world-class housing and building solutions with cutting-edge technology to all of our clients across Africa.</p>
            </p>
            <div className={classes.button}>
              {/* <Button >Download Now</Button> */}
            </div>
          </div>
          <div className={classes.image}>
            <img src={Prefabs} alt="House" />
          </div>
        </div>
      </div>
    
      <StyledSecondContainer gap={7}>
        <Typography color='primary' variant='h5'>Product Philosophy</Typography>
        <Stack direction='row' gap={3} alignItems='center'>
          <PhilosophyContainer />
          <Stack gap={1}>
            <Typography color='primary' fontStyle='italic' variant='body2'>Energy Saving</Typography>
            <Typography color='primary' fontStyle='italic' variant='body2'>Environmental Protection</Typography>
            <Typography color='primary' fontStyle='italic' variant='body2'>Safe</Typography>
            <Typography color='primary' fontStyle='italic' variant='body2'>Comfortable</Typography>
          </Stack>
        </Stack>
        <Typography variant='h5' color='primary'>"3R" Principle</Typography>
        <Grid container spacing={10}>
          {PRINCIPLES.map((principle, index) => {
            return (
              <Grid key={index} item xs={12} md={6} lg={4}>
                <Stack gap={2}>
                  <Typography color='primary' variant='h6'>{principle.title}</Typography>
                  <Typography variant='body2'>{principle.content}</Typography>
                  <PrincipleImageContainer>
                    <PrincipleImage alt={principle.title} src={principle.image} />
                  </PrincipleImageContainer>
                </Stack>
              </Grid>
            )
          })}
        </Grid>
      </StyledSecondContainer>
    </>
  )
}

export default AboutUs