import { Grid, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import image1 from '../../assets/house-types/textures.jpg';
// import image2 from '../../assets/house-types/BM2.jpg';
// import image3 from '../../assets/house-types/BM3.jpg';
// import image4 from '../../assets/house-types/BM4.jpg';
// import image5 from '../../assets/house-types/BM5.jpg';
// import image6 from '../../assets/house-types/BM6.jpg';
// import image7 from '../../assets/house-types/BM7.jpg';
// import image8 from '../../assets/house-types/BM8.jpg';
// import image9 from '../../assets/house-types/BM9.jpg';
// import image10 from '../../assets/house-types/BM10.jpg';
// import image11 from '../../assets/house-types/BM11.jpg';
// import image12 from '../../assets/house-types/BM12.jpg';
// import image13 from '../../assets/house-types/BM13.jpg';
// import image14 from '../../assets/house-types/BM14.jpg';
// import image15 from '../../assets/house-types/BM15.jpg';
// import image16 from '../../assets/house-types/BM16.jpg';
import image20 from '../../assets/building-materials/AA1.jpg';
import image21 from '../../assets/building-materials/AA2.jpg';
import image22 from '../../assets/building-materials/AA3.jpg';
import image23 from '../../assets/building-materials/AA4.jpg';
import image24 from '../../assets/building-materials/AA5.jpg';
import image25 from '../../assets/building-materials/AA6.jpg';
import image26 from '../../assets/building-materials/AA7.jpg';
import image27 from '../../assets/building-materials/AA8.jpg';
import image28 from '../../assets/building-materials/AA9.jpg';
import image29 from '../../assets/building-materials/AA10.jpg';
import image30 from '../../assets/building-materials/AA11.jpg';
import image31 from '../../assets/building-materials/AA12.jpg';
import image32 from '../../assets/building-materials/AA13.jpg';
import image33 from '../../assets/building-materials/AA14.jpg';
import image34 from '../../assets/building-materials/AA15.jpg';
import image35 from '../../assets/building-materials/AA16.jpg';
import image36 from '../../assets/building-materials/AA17.jpg';
import image37 from '../../assets/building-materials/AA18.jpg';
import image38 from '../../assets/building-materials/AA19.jpg';
import image39 from '../../assets/building-materials/AA20.jpg';
import image40 from '../../assets/building-materials/AA21.jpg';
import image41 from '../../assets/building-materials/AA22.jpg';
import image42 from '../../assets/building-materials/BB1.jpg';
import image43 from '../../assets/building-materials/BB2.jpg';
import image44 from '../../assets/building-materials/BB3.jpg';
import image45 from '../../assets/building-materials/BB4.jpg';
import image46 from '../../assets/building-materials/BB5.jpg';
import image47 from '../../assets/building-materials/BB6.jpg';
import image48 from '../../assets/building-materials/BB7.jpg';
import image49 from '../../assets/building-materials/BB8.jpg';
import image50 from '../../assets/building-materials/BB9.jpg';
import image51 from '../../assets/building-materials/BMM1.jpg';
import image52 from '../../assets/building-materials/BMM2.jpg';
import image53 from '../../assets/building-materials/BMM3.jpg';
import image54 from '../../assets/building-materials/BMM4.jpg';
import image55 from '../../assets/building-materials/BMM5.jpg';
import image56 from '../../assets/building-materials/BMM6.jpg';

const PRINCIPLES = [
  {image: image1}
]

const EXTERIORBOARD = [
  {image: image20},
  {image: image21},
  {image: image22},
];

const EXTERIORTILE = [
  {image: image23},
  {image: image24},
  {image: image25},
];

const PAINTING = [
  {image: image26},
  {image: image27},
  {image: image28},
];

const WINDOW = [
  {image: image29},
  {image: image30},
  {image: image31},
  {image: image32},
  {image: image33},
  {image: image34},
];

const ENTRANCEDOOR = [
  {image: image35},
  {image: image36},
  {image: image37},
  {image: image38},
  {image: image39},
  {image: image40},
  {image: image41},
];

const ROOFMATERIALS = [
  {image: image42},
  {image: image43},
  {image: image44},
  {image: image45},
  {image: image46},
  {image: image47},
];

const GUTTER = [
  {image: image48},
  {image: image49},
  {image: image50},
];

const INTERNALDECORATION = [
  {image: image51},
  {image: image52},
  {image: image53},
  {image: image54},
  {image: image55},
  {image: image56},
];


const StyledContainer = styled(Stack)(({theme}) => ({
  padding: 50,
}))

const PrincipleImageContainer = styled('div')(() => ({
  width: '100%',
  height: 800,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden'
}));

const PrincipleImageContainerTwo = styled('div')(() => ({
  width: '100%',
  height: 300,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden'
}));

const PrincipleImage = styled('img')(() => ({
  height: '100%',
  width: '100%',
  objectFit: 'contain'
}))

function BuildingMaterials() {
  return (
    <StyledContainer>
      <Typography color='primary' variant='h4' marginBottom={4}>Building Materials</Typography>

      {/* <Typography variant='h5' color='inherit' sx={{marginBottom: 2, marginTop: 5}}>Exterior Wall Materials</Typography> */}
      <Grid container spacing={8} >
        {PRINCIPLES.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={12} lg={12}>
                <PrincipleImageContainer>
                  <PrincipleImage src={principle.image} alt={principle.title} />
                </PrincipleImageContainer>
            </Grid>
          )
        })}
      </Grid>
      
      <Typography variant="h5" color="initial" marginBottom={2}>Exterior Wall Materials</Typography>
      <Typography variant="h6" color="initial">Exterior Wall Board</Typography>
      <Grid container spacing={8}>
        {EXTERIORBOARD.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <Stack>
                <PrincipleImageContainerTwo>
                  <PrincipleImage src={principle.image} />
                </PrincipleImageContainerTwo>
              </Stack>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant="h6" color="initial">Exterior Wall Tile</Typography>
      <Grid container spacing={8}>
        {EXTERIORTILE.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <Stack>
                <PrincipleImageContainerTwo>
                  <PrincipleImage src={principle.image} />
                </PrincipleImageContainerTwo>
              </Stack>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant="h6" color="initial">Painting</Typography>
      <Grid container spacing={8}>
        {PAINTING.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <Stack>
                <PrincipleImageContainerTwo>
                  <PrincipleImage src={principle.image} />
                </PrincipleImageContainerTwo>
              </Stack>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant="h5" color="initial">Window</Typography>
      <Grid container spacing={8}>
        {WINDOW.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <Stack>
                <PrincipleImageContainerTwo>
                  <PrincipleImage src={principle.image} />
                </PrincipleImageContainerTwo>
              </Stack>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant="h5" color="initial" marginTop={5}>Entrance Door</Typography>
      <Grid container spacing={8}>
        {ENTRANCEDOOR.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <Stack>
                <PrincipleImageContainerTwo>
                  <PrincipleImage src={principle.image} />
                </PrincipleImageContainerTwo>
              </Stack>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant="h5" color="initial" marginTop={5}>Roof Materials</Typography>
      <Grid container spacing={8}>
        {ROOFMATERIALS.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <Stack>
                <PrincipleImageContainerTwo>
                  <PrincipleImage src={principle.image} />
                </PrincipleImageContainerTwo>
              </Stack>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant="h5" color="initial" marginTop={5}>Gutter & Downspout</Typography>
      <Grid container spacing={8}>
        {GUTTER.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={6}>
              <Stack>
                <PrincipleImageContainerTwo>
                  <PrincipleImage src={principle.image} />
                </PrincipleImageContainerTwo>
              </Stack>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant="h5" color="initial" marginTop={5}>Internal Decoration Effect</Typography>
      <Grid container spacing={8}>
        {INTERNALDECORATION.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <Stack>
                <PrincipleImageContainerTwo>
                  <PrincipleImage src={principle.image} />
                </PrincipleImageContainerTwo>
              </Stack>
            </Grid>
          )
        })}
      </Grid>
      
    </StyledContainer>
  )
}

export default BuildingMaterials