import React from 'react';
import classes from './HomePage.module.css';
import Button from '../../components/Button/Button'
import B09 from '../../assets/B09.jpg'
import Footer from '../../components/Footer/Footer';
import image2 from '../../assets/Wooden1.jpg';
import image1 from '../../assets/HouseContainer1.jpg';
import image3 from '../../assets/house-types/Heavy1.jpg'
import { Grid, Stack, Typography, styled } from '@mui/material';
import Slides from '../../components/Slider/Slides';

const PRINCIPLES = [
  {image: image1, title: 'Wall Bearing System', description: '(1 - 3 storeys )' },
  {image: image2, title: 'Light Steel Column System', description: '(1 - 6 storeys )' },
  {image: image3, title: 'Heavy Steel/Steel-concrete Frame-wall System', description: '(1 - 30+ storeys )' },
]

const PrincipleImageContainer = styled('div')(({theme}) => ({
  width: '100%',
  height: 300,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden'
}));

const PrincipleImage = styled('img')(({theme}) => ({
  height: '100%',
  width: '100%',
  objectFit: 'cover'
}));

// const SliderMain = styled('div')(() => ({
//   width: '100%',
//   height: '100vh',
//   overflow: 'hidden',
//   "@keyframes pulsate": {
//     from: {
//       opacity: 1,
//       transform: "scale(1)"
//     },
//     to: {
//       opacity: 1,
//       transform: "scale(2)"
//     }
//   },
//   '& .text': {
//     animation: "pulsate 5s infinite ease",
//   },
//   // '@keyframes sliding': {
//   //   "0%": { marginRight: '0' },
//   //   "20%": { marginRight: '0' },
//   //   "25%": { marginRight: '-100%' },
//   //   "45%": { marginRight: '-100%' },
//   //   "50%": { marginRight: '-200%' },
//   //   "70%": { marginRight: '-200%' },
//   //   "75%": { marginRight: '-300%' },
//   //   "95%": { marginRight: '-300%' },
//   //   "100%": { marginRight: '-400%' },
//   // },
//   '& .slider': {
//     height: '100%',
//     width: '500%',
//   },
//   '& .content': {
//     height: '100%',
//     width: '20%',
//     backgroundColor: 'red'
//   },
//   '& .first': {
//     backgroundColor: 'green',
//     // animation: `sliding 20s infinite ease`
//   }
// }))

function HomePage() {

  const scrollToBottom = () => {
    const element = document.getElementById('bottom'); // Replace 'bottom' with the id of the element you want to scroll to.
    element.scrollIntoView({ behavior: 'smooth' });
  };
  
  return (
    <>
    <div className={classes.container}>
      {/* <img src={B09} alt="" /> */}
      {/* <div className={classes.content}>
        <h3>Housing & Building</h3>
        <p>Infracon Shelters Africa Limited, founded in 2018,is dedicated to the design, production, construction, promotion and servicing of modern, contemporary housing units. The company utilizes world-class light steel structure design software and precision equipment, so that components of integrated houses can be automatically made according to the customers' requirements and aspirations.</p>
        <Button onClick={scrollToBottom}>Contact Us</Button>
      </div> */}
      <div className={classes.slider}>
        <Slides />
      </div>
      {/* <SliderMain> */}
        {/* <div className='slider'>
          <p className='text'>Hello</p> */}
          {/* <div className='content first' />
          <div className='content' />
          <div className='content' />
          <div className='content' />
          <div className='content' /> */}
        {/* </div> */}
      {/* </SliderMain> */}
      <div>
      
      </div>
    </div>

    {/* <h3 className={classes.heading2}>Technology Systems</h3> */}
    {/* <div className={classes['div-container']}> */}
      {/* <div className={classes.tech}> */}
        {/* <div className={classes.left}> */}
          {/* <p>Wall Bearing System</p> */}
          {/* <img src={Container} alt="House Container" /> */}
          {/* <p>(1 - 3 storeys )</p> */}
        {/* </div> */}
        {/* <div className={classes.right}> */}
          {/* <p>Light Steel Column System</p> */}
          {/* <img src={Wooden} alt="Wooden" /> */}
          {/* <p>(1 - 6 storeys )</p> */}
        {/* </div> */}
      {/* </div> */}
    {/* </div> */}

    <Grid container spacing={5} marginTop={3}>
      {PRINCIPLES.map((principle, index) => {
        return(
          <Grid item key={index} xs={12} md={6} lg={4} marginTop={2}>
            <Stack alignItems='center' justifyContent='center'>
              <Typography variant="h6" color="initial">{principle.title}</Typography>
              <Typography variant="body1" color="initial">{principle.description}</Typography>
              <PrincipleImageContainer>
                <PrincipleImage src={principle.image} alt="" />
              </PrincipleImageContainer>
            </Stack>
          </Grid>
        )
      })}
    </Grid>
    
    <p id='bottom'></p>
   </>
  )
}

export default HomePage