import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { IconButton, Link, Menu, MenuItem, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Menu as MenuIcon } from '@mui/icons-material';
import Logo from '../Logo';

const LINKS = [
  { name: 'Home', link: '/' },
  { name: 'About Us', link:'/about-us' },
  { name: 'Technology Systems', link: '/technology-systems' },
  { name: 'Housing Features', link: '/housing-features' },
  { name: 'Operation Capability', link: '/operation-capability' },
  { name: 'Housing Types', link: '/housing-types' },
  { name: 'Building Materials', link: '/building-materials' }
]

export default function TopBar() {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {LINKS.map((link, index) => {

          const navigationHandler = () => {
            handleClose();
            navigate(link.link)
          }

          return (
            <MenuItem key={index} onClick={navigationHandler}>{link.name}</MenuItem>
          )
        })}
      </Menu>
  )

  return (
    <>
      {renderMenu}
      <Box sx={{ flexGrow: 1, marginBottom: 15 }}>
        <AppBar position="absolute" color='transparent'  elevation={0}>
          <Toolbar>
            <Stack sx={{ pt: 2, width: '100%' }} direction='row' alignItems='center' justifyContent={{ xs: 'space-between', md: 'flex-start' }} gap={10}>
              <Logo />
              {!isSmallScreen && <Stack gap={7} direction='row' alignItems='center'>
                {LINKS.map((link, index) => {
                  return (
                    <Link key={index} sx={{ color: 'primary' }} underline='none' component={RouterLink} to={link.link}>{link.name}</Link>
                  )
                })}
              </Stack>}
              {isSmallScreen && <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <MenuIcon color='inherit' />
              </IconButton>}
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
    
    </>
  );
}
