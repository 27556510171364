import { Grid, Stack, Typography, styled } from '@mui/material'
import React from 'react';
import Chart from '../../assets/F2.png'

const CONSTRUCTION = [
  { number1: '100' ,number2: '15', description: 'It takes 15 days to complete a 100 square meters residence.'},
  { number1: '200' ,number2: '60', description: 'It takes 60 days to complete a 200 square meters villa.'},
  { number1: '10000' ,number2: '100', description: 'It takes 100 days to complete a 10,000 square meters public building.'},
];

const StyledContainer = styled(Stack)(({theme}) => ({
  padding: 24,
  alignItems: 'center',
  textAlign: 'justify',
  [theme.breakpoints.down('md')]: {
    padding: 12
  }
}));

const InnerStyledContainer = styled(Stack)(({theme}) => ({
  width: '70%',
  [theme.breakpoints.down('md')]: {
    width: '90%'
  }
}))

const ImageContainer = styled(Grid)(({theme}) => ({
  padding: '10rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

const Image = styled('img')(({theme}) => ({
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}))

function OperationCapability() {
  return (
    <>
    <StyledContainer>
      <InnerStyledContainer>
      <Typography variant="h4" color="primary" marginBottom={2}>Operation Capability</Typography>

      <Stack marginBottom={4}>
        <Typography variant="h5" color="initial" marginBottom={1}>Production</Typography>
        <Typography variant="body1" color="initial" lineHeight={2}>CAD/CAM software is utilized to accelerate mass production of housing components with high precision, thereby enhancing production efficiency and quality.</Typography>
      </Stack>

      <Stack marginBottom={4}>
        <Typography variant="h5" color="initial" marginBottom={1}>Construction</Typography>
        <Typography variant="body1" color="initial">Experienced project teams comprised of engineers, technicians and skilled workers are tasked with undertaking construction work and delivering projects on time and within budget.</Typography>
        <Grid container spacing={2} marginTop={2}>
          {CONSTRUCTION.map((principle, index) => {
            return(
              <Grid key={index} item xs={12} md={6} lg={4}>
                <Stack  sx={{border: '2px solid gray', padding: '1rem', borderRadius: '0.5rem'}} >
                  {/* <Typography variant="h2" color="primary">{principle.number1}</Typography> */}
                  {/* <Typography variant="h4" color="initial">{principle.number2}</Typography> */}
                  <Typography variant="body1" color="initial">{principle.description}</Typography>
                </Stack>
              </Grid>
            )
          })}
        </Grid>
      </Stack>

      <Stack marginBottom={4}>
        <Typography variant="h5" color="initial" marginBottom={1}>After-Sale Service</Typography>
        <Typography variant="body1" color="initial">Construction engineering quality warranty</Typography>
        <Typography variant="body1" color="initial" lineHeight={2}>1. The expected service life of infrastructure, foundation and structure of the house are based on national or international design standards as contained in the design documents.</Typography>
        <Typography variant="body1" color="initial" lineHeight={2}>2. The water resistance service life of the roof, restrooms, rooms and exterior walls shall be longer than 5 years. </Typography>
        <Typography variant="body1" color="initial" lineHeight={2}>3. The service life of heating and cooling system shall be longer than 2 reasonable working period. </Typography>
        <Typography variant="body1" color="initial" lineHeight={2}>4. The service life of electrical systems, water supply and drainage systems, equipment installation and decoration shall be longer than 2 years.</Typography>

        <Typography variant="body1" color="initial" lineHeight={2} marginTop={2}>According to the relevant provisions of the after—sale service, we visit the customer at the first year, third and fifth year and deal with any suggestions and feedback immediately.</Typography>
      </Stack>

      <Stack marginBottom={4}>
        <Typography variant="h5" color="initial" marginBottom={1}>Management Ability</Typography>
        <Typography variant="body1" color="initial" lineHeight={2} >Our management team includes technical experts that have in-depth knowledge and experience in the industry.
        We implement honest and transparent management system which makes the workflow and tasks clear and controllable. We pride ourselves with efficient multi-project management ability.
        Our company resources are aligned with project requirements to ensure timely and quality delivery of projects.
        </Typography>
      </Stack>

      <Stack>
        <Typography variant="h5" color="initial" marginBottom={1}>EPC Service</Typography>
        <Grid container spacing={10}>
          <ImageContainer item xs={12} md={6} lg={12}>
            <Image src={Chart} alt="" />
          </ImageContainer>
        </Grid>
      </Stack>
      </InnerStyledContainer>
    </StyledContainer>
    </>
  )
}

export default OperationCapability