import React, { useState } from 'react';
import { TextField, Button, MenuItem, Grid, Container, Typography } from '@mui/material';
import emailjs from 'emailjs-com';
import { CountryRegionData } from 'react-country-region-selector';

function ContactsForm() {

  
  const countries = CountryRegionData.map(count => ({ label: count[0], value: count[0] }))
  
// const countries = [
//     { value: 'usa', label: 'USA' },
//     { value: 'canada', label: 'Canada' },
//     { value: 'uk', label: 'UK' },
//     // Add more countries as needed
// ];
  
    const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      phone: '',
      subject: '',
      message: '',
    });
    const [emailError, setEmailError] = useState(false);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
  
      // Validate email address
      if (name === 'email') {
        const isValidEmail = value.includes('@');
        setEmailError(!isValidEmail);
      }
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Handle form submission here
      console.log(formData);
    

    const smtpServiceId = 'service_qe2ngyc'; // This will be provided by EmailJS
    const templateId = 'template_w3qx4x8';

    emailjs.init('o6MNMAsDHi4_49gz5');

    emailjs.send(smtpServiceId, templateId, {
      to_email: 'info@infraconsheltersafrica.com',
      from_name: `${formData.firstName}`,
      message: 'This is a test email from EmailJS with Hostinger SMTP.',
    })
    .then((response) => {
      console.log('Email sent:', response);
    })
    .catch((error) => {
      console.error('Email could not be sent:', error);
    });

  };

  

  return (
    <Container>
        <Typography variant="h5" color="initial" marginBottom={2} textAlign='center'>CONTACT THE TEAM AT INFRACON SHELTERS AFRICA</Typography>
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="First Name"
            variant="outlined"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            fullWidth
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Last Name"
            variant="outlined"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            fullWidth
            size='small'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="E-mail Address"
            variant="outlined"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            fullWidth
            error={emailError}
            helperText={emailError ? 'Please enter a valid email address' : ''}
            size='small'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            label="Country"
            variant="outlined"
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            fullWidth
            size='small'
          >
            {countries.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Phone/Mobile"
            variant="outlined"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            fullWidth
            size='small'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Subject"
            variant="outlined"
            name="subject"
            value={formData.subject}
            onChange={handleInputChange}
            fullWidth
            size='small'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Message"
            variant="outlined"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            fullWidth
            multiline
            rows={3}
            size='small'
          />
        </Grid>
      </Grid>
      <Button onClick={handleSubmit} type="submit" variant="contained" color="primary" sx={{marginTop: 2, marginBottom: 8}}>
        Submit
      </Button>
    </form>
  </Container>
  )
}


export default ContactsForm