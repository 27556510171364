import { Grid, Stack, Typography, styled } from '@mui/material'
import React from 'react';

import image1 from '../../assets/house-types/DT1.jpg';
import image2 from '../../assets/house-types/DT2.jpg';
import image3 from '../../assets/house-types/DT3.jpg';
import image4 from '../../assets/house-types/DT4.jpg';
import image5 from '../../assets/house-types/DT5.jpg';
import image6 from '../../assets/house-types/DT6.jpg';
import image7 from '../../assets/house-types/DT7.jpg';
import image8 from '../../assets/house-types/DT8.jpg';
import image9 from '../../assets/house-types/DT9.jpg';
import image10 from '../../assets/house-types/DT10.jpg';
import image11 from '../../assets/house-types/EC1.jpg';
import image12 from '../../assets/house-types/EC2.jpg';
import image13 from '../../assets/house-types/EC3.jpg';
import image14 from '../../assets/house-types/EC4.jpg';
import image15 from '../../assets/house-types/EC5.jpg';
import image16 from '../../assets/house-types/EC6.jpg';
import image17 from '../../assets/house-types/EC7.jpg';
import image18 from '../../assets/house-types/EC8.jpg';
import image19 from '../../assets/house-types/EC9.jpg';
import image20 from '../../assets/house-types/EC10.jpg';
import image21 from '../../assets/house-types/EC11.jpg';
import image22 from '../../assets/house-types/EC12.jpg';
import image23 from '../../assets/house-types/AT11.jpg';
import image24 from '../../assets/house-types/AT12.jpg';
import image25 from '../../assets/house-types/AT22.jpg';
import image26 from '../../assets/house-types/AT23.jpg';
import image27 from '../../assets/house-types/AT24.jpg';
import image28 from '../../assets/house-types/AT25.jpg';
import image29 from '../../assets/house-types/AT26.jpg';
import image30 from '../../assets/house-types/AT33.jpg';
import image31 from '../../assets/house-types/AT34.jpg';
import image32 from '../../assets/house-types/AT33.jpg';
import image33 from '../../assets/house-types/AT44.jpg';
import image34 from '../../assets/house-types/AT45.jpg';
import image35 from '../../assets/house-types/AT46.jpg';
import image36 from '../../assets/house-types/AT47.jpg';
import image37 from '../../assets/house-types/AT48.jpg';
import image38 from '../../assets/house-types/AT45.jpg';
import image39 from '../../assets/house-types/AT55.jpg';
import image40 from '../../assets/house-types/AT56.jpg';
import image41 from '../../assets/house-types/AT57.jpg';
import image42 from '../../assets/house-types/AT58.jpg';
import image43 from '../../assets/house-types/AT59.jpg';
import image44 from '../../assets/house-types/CV1.jpg';
import image45 from '../../assets/house-types/CV2.jpg';
import image46 from '../../assets/house-types/CV3.jpg';
import image47 from '../../assets/house-types/CV4.jpg';
import image48 from '../../assets/house-types/CV5.jpg';
import image49 from '../../assets/house-types/CV6.jpg';
import image50 from '../../assets/house-types/LT1.jpg';
import image51 from '../../assets/house-types/LT2.jpg';
import image52 from '../../assets/house-types/LT3.jpg';
import image53 from '../../assets/house-types/LT4.jpg';
import image54 from '../../assets/house-types/LT5.jpg';
import image55 from '../../assets/house-types/LT6.jpg';
import image56 from '../../assets/house-types/LT7.jpg';
import image57 from '../../assets/house-types/LT8.jpg';
import image58 from '../../assets/house-types/LT9.jpg';
import image59 from '../../assets/house-types/LT10.jpg';
import image60 from '../../assets/house-types/MB1.jpg';
import image61 from '../../assets/house-types/MB2.jpg';
import image62 from '../../assets/house-types/MB3.jpg';
import image63 from '../../assets/house-types/MB4.jpg';
import image64 from '../../assets/house-types/MB5.jpg';
import image65 from '../../assets/house-types/MB6.png';
import image66 from '../../assets/house-types/MB7.jpg';
import image67 from '../../assets/house-types/MB9.jpg';
import image68 from '../../assets/house-types/MB10.jpg';
import image69 from '../../assets/house-types/ST1.jpg';
import image70 from '../../assets/house-types/ST2.jpg';
import image71 from '../../assets/house-types/ST3.jpg';
import image72 from '../../assets/house-types/ST4.jpg';
import image73 from '../../assets/house-types/WV1.jpg';
import image74 from '../../assets/house-types/WV2.jpg';
import image75 from '../../assets/house-types/WV3.jpg';
import image76 from '../../assets/house-types/WV4.jpg';
import image77 from '../../assets/house-types/WV5.jpg';
import image78 from '../../assets/house-types/WV6.jpg';
import image79 from '../../assets/house-types/FT1.jpg';
import image80 from '../../assets/house-types/FT2.jpg';
import image81 from '../../assets/house-types/CE1.png';
import image82 from '../../assets/house-types/CE2.png';
import image83 from '../../assets/house-types/CE3.jpg';
import image84 from '../../assets/house-types/CE4.png';
import image85 from '../../assets/house-types/CE5.jpg';
import image86 from '../../assets/house-types/CE6.jpg';
import image87 from '../../assets/house-types/CE7.jpg';
import image88 from '../../assets/house-types/CE8.png';



const EXPANDABLE = [
  {image: image11},
  {image: image12},
  {image: image13},
  {image: image14},
  {image: image15},
  {image: image16},
  {image: image17},
  {image: image18},
  {image: image19},
  {image: image20},
  {image: image21},
  {image: image22},
];

const DETACHABLE = [
  {image: image1},
  {image: image2},
  {image: image3},
  {image: image4},
  {image: image5},
  {image: image6},
  {image: image7},
  {image: image8},
  {image: image9},
  {image: image10},
];

const FLATPACK = [
  {image: image23},
  {image: image24},
  {image: image25},
  // {image: image26},
  {image: image26},
  {image: image27},
  {image: image28},
  // {image: image29},
  // {image: image30},
  {image: image31},
  {image: image32},
  {image: image33},
  // {image: image34},
  {image: image35},
  {image: image36},
  {image: image37},
  {image: image38},
  {image: image39},
  {image: image40},
  {image: image41},
  {image: image42},
  {image: image43},
];

const CLOUDVILLA = [
  {image: image44},
  {image: image45},
  {image: image46},
  {image: image47},
  {image: image48},
  {image: image49}
];

const LOFT = [
  {image: image50},
  {image: image51},
  {image: image52},
  {image: image53},
  {image: image54},
  {image: image55},
  // {image: image56},
  {image: image57},
  // {image: image58},
  {image: image59},
];

const MOONBOX = [
  // {image: image60},
  {image: image61},
  {image: image62},
  // {image: image63},
  {image: image64},
  {image: image65},
  {image: image66},
  {image: image67},
  {image: image68}
];

const STARWHISPER = [
  {image: image69},
  {image: image70},
  {image: image71},
  {image: image72},
];

const WOODENVILLA = [
  {image: image73},
  {image: image74},
  {image: image75},
  {image: image76},
  {image: image77},
  {image: image78},
];

const FOUNDATION = [
  {image: image79},
  {image: image80},
];

const CEMENTBOARD = [
  {image: image81},
  {image: image82},
  {image: image83},
  {image: image84},
  {image: image85},
  {image: image86},
  {image: image87},
  {image: image88},
];

const StyledContainer = styled(Stack)(({theme}) => ({
  padding: 50
}));

const PrincipleImageContainer = styled('div')(({theme}) => ({
  width: '100%',
  height: 300,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden'
}));

const ImageContainer =  styled('img')(({theme}) => ({
  height: '100%',
  width: '100%',
  objectFit: 'cover'
}))

function HousingType() {
  return (
    <StyledContainer>
      <Typography color='primary' variant='h4'>Housing Types</Typography>

      <Typography variant='h5' color='inherit' sx={{marginBottom: 2, marginTop: 5}}>A) Sandwich Panel Wall Type</Typography>
      <Typography variant="h6" color="initial">1. Expandable Modular House</Typography>
      <Grid container spacing={10}>
        {EXPANDABLE.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <PrincipleImageContainer>
                <ImageContainer src={principle.image} alt="" />
              </PrincipleImageContainer>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant='h6' color='inherit' sx={{marginBottom: 2, marginTop: 5}}>2. Detachable Modular House</Typography>
      <Grid container spacing={10}>
        {DETACHABLE.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <PrincipleImageContainer>
                <ImageContainer src={principle.image} alt="" />
              </PrincipleImageContainer>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant='h6' color='inherit' sx={{marginBottom: 2, marginTop: 5}}>3. Flat Pack Modular House</Typography>
      <Grid container spacing={10}>
        {FLATPACK.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <PrincipleImageContainer>
                <ImageContainer src={principle.image} alt="" />
              </PrincipleImageContainer>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant='h6' color='inherit' sx={{marginBottom: 2, marginTop: 5}}>B) Cement Fiber Board Wall Type</Typography>
      <Grid container spacing={10}>
        {CEMENTBOARD.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <PrincipleImageContainer>
                <ImageContainer src={principle.image} alt="" />
              </PrincipleImageContainer>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant="h5" color="initial" sx={{marginBottom: 2, marginTop: 5}}>C) Light Steel Column Type</Typography>

      <Typography variant='h6' color='inherit' sx={{marginBottom: 2, marginTop: 1}}>Light Steel Type 1 House</Typography>
      <Grid container spacing={10}>
        {CLOUDVILLA.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <PrincipleImageContainer>
                <ImageContainer src={principle.image} alt="" />
              </PrincipleImageContainer>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant='h6' color='inherit' sx={{marginBottom: 2, marginTop: 5}}>Light Steel Type 2 House</Typography>
      <Grid container spacing={10}>
        {LOFT.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <PrincipleImageContainer>
                <ImageContainer src={principle.image} alt="" />
              </PrincipleImageContainer>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant='h6' color='inherit' sx={{marginBottom: 2, marginTop: 5}}>Light Steel Type 3 House</Typography>
      <Grid container spacing={10}>
        {MOONBOX.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <PrincipleImageContainer>
                <ImageContainer src={principle.image} alt="" />
              </PrincipleImageContainer>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant='h6' color='inherit' sx={{marginBottom: 2, marginTop: 5}}>Light Steel Type 4 House</Typography>
      <Grid container spacing={10}>
        {STARWHISPER.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <PrincipleImageContainer>
                <ImageContainer src={principle.image} alt="" />
              </PrincipleImageContainer>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant='h6' color='inherit' sx={{marginBottom: 2, marginTop: 5}}>Light Steel Type 5 House</Typography>
      <Grid container spacing={10}>
        {WOODENVILLA.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <PrincipleImageContainer>
                <ImageContainer src={principle.image} alt="" />
              </PrincipleImageContainer>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant='h5' color='inherit' sx={{marginBottom: 2, marginTop: 5}}>Foundation</Typography>
      <Grid container spacing={10}>
        {FOUNDATION.map((principle, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <PrincipleImageContainer>
                <ImageContainer src={principle.image} alt="" />
              </PrincipleImageContainer>
            </Grid>
          )
        })}
      </Grid>

    </StyledContainer>
  )
}

export default HousingType