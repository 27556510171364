import { Link } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import logoImage from '../../assets/Logo1.jpg'

const Logo = () => {
  return (
    <Link component={RouterLink} to='/'>
        <img style={{ height: 80 }} alt='logo' src={logoImage} />
    </Link>
  )
}

export default Logo