import { Grid, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import firstImage from '../../assets/W1.jpg';
import secondImage from '../../assets/W2.jpg';
import thirdImage from '../../assets/W3.jpg';
import fourthImage from '../../assets/W4.jpg';
import fifthImage from '../../assets/W5.jpg';
import sixthImage from '../../assets/W6.jpg';
import seventhImage from '../../assets/W7.jpg';
import image8 from '../../assets/V1.jpg';
import image9 from '../../assets/V2.jpg';
import image10 from '../../assets/V3.jpg';
import image11 from '../../assets/V4.jpg';
import image12 from '../../assets/V5.jpg';
import image13 from '../../assets/V6.jpg';
import image14 from '../../assets/V7.jpg';
import image15 from '../../assets/V8.jpg';
import image16 from '../../assets/V9.jpg';
import image17 from '../../assets/V10.jpg';
import image18 from '../../assets/H1.jpg';
import image19 from '../../assets/H2.jpg';
import image20 from '../../assets/H3.jpg';
import image21 from '../../assets/H4.jpg';
import image22 from '../../assets/H5.jpg';
import image23 from '../../assets/H6.jpg';
import image24 from '../../assets/L1.jpg';
import image25 from '../../assets/L2.jpg';
import image26 from '../../assets/L3.jpg';
import image27 from '../../assets/L4.jpg';
import image28 from '../../assets/L5.jpg';
import image29 from '../../assets/L6.jpg';
import image30 from '../../assets/M1.jpg';
import image31 from '../../assets/M2.jpg';
import image32 from '../../assets/M3.jpg';
import image33 from '../../assets/M4.jpg';
import image34 from '../../assets/M5.jpg';
import image35 from '../../assets/M6.jpg';
import image36 from '../../assets/S1.jpg';
import image37 from '../../assets/S2.jpg';
import image38 from '../../assets/S3.jpg';
import image39 from '../../assets/S4.jpg';
import image40 from '../../assets/S5.jpg';
import image41 from '../../assets/S6.jpg';
import image42 from '../../assets/C1.jpg';
import image43 from '../../assets/C2.jpg';
import image44 from '../../assets/C3.jpg';
import image45 from '../../assets/C4.jpg';
import image46 from '../../assets/C5.jpg';
import image47 from '../../assets/C6.jpg';
import image48 from '../../assets/WV1.jpg';
import image49 from '../../assets/WV2.jpg';
import image50 from '../../assets/WV3.jpg';
import image51 from '../../assets/WV4.jpg';
import image52 from '../../assets/WV5.jpg';
import image53 from '../../assets/WV6.jpg';
import image54 from '../../assets/house-types/Heavy1.jpg';
import image55 from '../../assets/house-types/Heavy2.jpg';
import image56 from '../../assets/house-types/Heavy3.jpg';

const PRINCIPLES = [
  {heading: 'Production', description: '(1-3 storeys)', image: firstImage, title: 'image-title' },
  {heading: 'Foundation', description: '(1-6 storeys)', image: secondImage, title: 'image-title'},
  {heading: 'Wall', description: '(30 layers below)', image: thirdImage, title: 'image-title' },
  // {heading: 'Roof Truss', description: '(30 layers below)', image: fourthImage, title: 'image-title' },
  {heading: 'Floor Slub', description: '(30 layers below)', image: fifthImage, title: 'image-title' },
  {heading: 'Exterior Wall', description: '(30 layers below)', image: sixthImage, title: 'image-title' },
  {heading: 'Completion', description: '(30 layers below)', image: seventhImage, title: 'image-title' },
];

const VILLA = [
  { image: image8 },
  // { image: image9 },
  { image: image10 },
  { image: image11 },
  { image: image12 },
  { image: image13 },
  { image: image14 },
  { image: image15 },
  { image: image16 },
  { image: image17 },
];

const HONEYCOMB = [
  { image: image18 },
  // { image: image19 },
  { image: image20 },
  { image: image21 },
  { image: image22 },
  { image: image23 },
];

const LOFT = [
  { image: image24 },
  { image: image25 },
  // { image: image26 },
  // { image: image27 },
  { image: image28 },
  { image: image29 },
];

const MOONBOX = [
  { image: image30 },
  { image: image31 },
  // { image: image32 },
  { image: image33 },
  { image: image34 },
  { image: image35 },
];

const STAR_WHISPER = [
  { image: image36 },
  // { image: image37 },
  { image: image38 },
  { image: image39 },
  { image: image40 },
  // { image: image41 },
];

const WOODEN_WHISPER = [
  { image: image42 },
  { image: image43 },
  // { image: image44 },
  { image: image45 },
  { image: image46 },
  { image: image47 },
];

const WOODEN_VILLA = [
  { image: image48 },
  { image: image49 },
  { image: image50 },
  { image: image51 },
  { image: image52 },
  { image: image53 },
];

const HEAVYSTEEL = [
  { image: image54 },
  { image: image55 },
  { image: image56 },
];



const StyledFirstContainer =  styled(Stack)(({theme}) => ({
  padding: 24
}));

const PrincipleImageContainer = styled('div')(() => ({
  width: '100%',
  height: 300,
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  flexDirection: 'column'
}));

const PrincipleImage = styled('img')(() => ({
  height: '100%',
  width: '100%',
  objectFit: 'cover'
}))



function TechnologySystems() {
  return (
    <>
    <StyledFirstContainer>
      <Typography marginBottom={3} variant="h4" color="primary">Technology Systems</Typography>
      <Typography marginBottom={1} variant="h5" color="initial">Wall Bearing System</Typography>
      <Typography marginBottom={3} variant="body1" color="initial" lineHeight={2}>
      For single storey houses (upto 3 storeys), the structure is designed and fabricated as a wall-bearing system using CAD/CAM software. Our technolgy allows for alternate material types for the walls depending on the client's preference and budget. The walls are framed with dense light steel studs.
      </Typography>
      <Grid container spacing={10}>
        {PRINCIPLES.map((principle, index) => {
          return(
            <Grid key={index} item xs={12} md={6} lg={4}>
              <Stack>
                <PrincipleImageContainer>
                  <PrincipleImage src={principle.image} alt={principle.title} />
                  {/* <Typography fontSize={16}  color="primary">{principle.heading}</Typography> */}
                  {/* <Typography variant="2" color="initial">{principle.description}</Typography> */}
                </PrincipleImageContainer>  
              </Stack>
            </Grid>
          )
        })}
      </Grid>

      <Typography marginTop={5} marginBottom={1} variant="h5" color="initial">Light Steel Frame System</Typography>
      <Typography marginBottom={3} variant="body1" color="initial" lineHeight={2}>
      This System is developed with light steel structure and wall enclosure, connected and fixed by different metal connections. Typically, upto 6 storeys can be constructed using this system. Various material types are available for the wall. An option involving steel and wood composite structure typically yields 30% cost reduction. The system is specially suitable for hospitality as well as residential buildings.
      The beam and column are light steel thin-walled tube, while the wall is light steel frame. This kind of system enables large-scale production of the structure for multi-storey buildings that are light weight, seismically safe, well insulated and comfortable. 
      </Typography>
        <Typography variant="h6" color="initial" marginBottom={2}>Villa</Typography>
      <Grid container spacing={10}>
        {VILLA.map((principle, index) => {
          return(
            <Grid key={index} item xs={12} md={6} lg={4}>
              <Stack>
                <PrincipleImageContainer>
                  <PrincipleImage src={principle.image} alt={principle.title} />
                  {/* <Typography fontSize={16}  color="primary">{principle.heading}</Typography> */}
                  {/* <Typography variant="2" color="initial">{principle.description}</Typography> */}
                </PrincipleImageContainer>  
              </Stack>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant="h6" color="initial" marginBottom={2} marginTop={2}>Honey Comb</Typography>
      <Grid container spacing={10}>
        {HONEYCOMB.map((principle, index) => {
          return(
            <Grid key={index} item xs={12} md={6} lg={4}>
              <Stack>
                <PrincipleImageContainer>
                  <PrincipleImage src={principle.image} alt={principle.title} />
                  {/* <Typography fontSize={16}  color="primary">{principle.heading}</Typography> */}
                  {/* <Typography variant="2" color="initial">{principle.description}</Typography> */}
                </PrincipleImageContainer>  
              </Stack>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant="h6" color="initial" marginBottom={2} marginTop={2}>Loft</Typography>
      <Grid container spacing={10}>
        {LOFT.map((principle, index) => {
          return(
            <Grid key={index} item xs={12} md={6} lg={4}>
              <Stack>
                <PrincipleImageContainer>
                  <PrincipleImage src={principle.image} alt={principle.title} />
                  {/* <Typography fontSize={16}  color="primary">{principle.heading}</Typography> */}
                  {/* <Typography variant="2" color="initial">{principle.description}</Typography> */}
                </PrincipleImageContainer>  
              </Stack>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant="h6" color="initial" marginBottom={2} marginTop={2}>Moonbox</Typography>
      <Grid container spacing={10}>
        {MOONBOX.map((principle, index) => {
          return(
            <Grid key={index} item xs={12} md={6} lg={4}>
              <Stack>
                <PrincipleImageContainer>
                  <PrincipleImage src={principle.image} alt={principle.title} />
                  {/* <Typography fontSize={16}  color="primary">{principle.heading}</Typography> */}
                  {/* <Typography variant="2" color="initial">{principle.description}</Typography> */}
                </PrincipleImageContainer>  
              </Stack>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant="h6" color="initial" marginBottom={2} marginTop={2}>Star Whisper</Typography>
      <Grid container spacing={10}>
        {STAR_WHISPER.map((principle, index) => {
          return(
            <Grid key={index} item xs={12} md={6} lg={4}>
              <Stack>
                <PrincipleImageContainer>
                  <PrincipleImage src={principle.image} alt={principle.title} />
                  {/* <Typography fontSize={16}  color="primary">{principle.heading}</Typography> */}
                  {/* <Typography variant="2" color="initial">{principle.description}</Typography> */}
                </PrincipleImageContainer>  
              </Stack>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant="h6" color="initial" marginBottom={2} marginTop={2}>Wooden Whisper</Typography>
      <Grid container spacing={10}>
        {WOODEN_WHISPER.map((principle, index) => {
          return(
            <Grid key={index} item xs={12} md={6} lg={4}>
              <Stack>
                <PrincipleImageContainer>
                  <PrincipleImage src={principle.image} alt={principle.title} />
                  {/* <Typography fontSize={16}  color="primary">{principle.heading}</Typography> */}
                  {/* <Typography variant="2" color="initial">{principle.description}</Typography> */}
                </PrincipleImageContainer>  
              </Stack>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant="h6" color="initial" marginBottom={2} marginTop={2}>Wooden Villa</Typography>
      <Grid container spacing={10}>
        {WOODEN_VILLA.map((principle, index) => {
          return(
            <Grid key={index} item xs={12} md={6} lg={4}>
              <Stack>
                <PrincipleImageContainer>
                  <PrincipleImage src={principle.image} alt={principle.title} />
                  {/* <Typography fontSize={16}  color="primary">{principle.heading}</Typography> */}
                  {/* <Typography variant="2" color="initial">{principle.description}</Typography> */}
                </PrincipleImageContainer>  
              </Stack>
            </Grid>
          )
        })}
      </Grid>

      <Typography variant="h6" color="initial" marginBottom={2} marginTop={2}>Heavy Steel/Steel-concrete Frame-wall System</Typography>
      <Typography variant="body1" color="initial" marginBottom={2}>The bearing structure is formed by heavy steel beam and column or concrete.The exterior cladding is composed of maintenance-free precast boards that are manufactured offsite, delivered and installed onsite quickly. This system is suited for high rise buildings (upto 30 plus storeys).</Typography>
      <Grid container spacing={10}>
        {HEAVYSTEEL.map((principle, index) => {
          return(
            <Grid key={index} item xs={12} md={6} lg={4}>
              <Stack>
                <PrincipleImageContainer>
                  <PrincipleImage src={principle.image} alt={principle.title} />
                  {/* <Typography fontSize={16}  color="primary">{principle.heading}</Typography> */}
                  {/* <Typography variant="2" color="initial">{principle.description}</Typography> */}
                </PrincipleImageContainer>  
              </Stack>
            </Grid>
          )
        })}
      </Grid>

    </StyledFirstContainer>
    
    </>
  )
}

export default TechnologySystems