import React from 'react'
import Router from './routes';
import ThemeProvider from './components/Theme/theme';
import TopBar from './components/top-bar/top-bar';
import Footer from './components/Footer/Footer';
import ContactsForm from './components/Form/ContactsForm';

function App() {
  return (
    <ThemeProvider>
      <TopBar />
      <Router />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
