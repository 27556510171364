import React from 'react';
import { useRoutes } from 'react-router-dom';
import HomePage from '../screens/homepage/HomePage';
import AboutUs from '../screens/about-us/AboutUs';
import BuildingMaterials from '../screens/building-materials/BuildingMaterials';
import HousingFeatures from '../screens/housing-features-advantages/HousingFeatures';
import HousingType from '../screens/housing-types/HousingType';
import OperationCapability from '../screens/operation-capability/OperationCapability';
import TechnologySystems from '../screens/technology-systems/TechnologySystems';


const Router = () => {
    const router = useRoutes([
        { path: '/', element: <HomePage /> },
        { path: 'about-us', element: <AboutUs /> },
        { path: 'building-materials', element: <BuildingMaterials /> },
        { path: '/housing-features', element: <HousingFeatures /> },
        { path: 'housing-types', element: <HousingType /> },
        { path: 'operation-capability', element: <OperationCapability /> },
        { path: '/technology-systems', element: <TechnologySystems /> }
    ]);

    return router;
}

export default Router;