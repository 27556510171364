import ImageSlider from "./ImageSlider";
import Image1 from '../../assets/Wooden11.jpg';
import Image2 from '../../assets/home11.jpg';
import Image3 from  '../../assets/Heavy11.jpg';
import Image4 from '../../assets/HouseContainer11.jpg'


const App = () => {
  const slides = [
    { url: Image2, title: "beach" },
    { url: Image1, title: "boat" },
    { url: Image3, title: "forest" },
    { url: Image4, title: "house" }
  ];
  const containerStyles = {
    width: "95rem",
    height: "45rem",
    margin: "0 auto",
    // display: 'flex',
    // justifyContent: 'center'
  };
  
  // Media query for smaller screens (e.g., mobile devices)
  const smallerScreenStyles = {
    width: "24rem", // Adjust the width for smaller screens
    height: "20rem", // Adjust the height to maintain aspect ratio
    margin: '0 1rem',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  };
  
  // Merge the original styles with the styles for smaller screens using a ternary operator
  const updatedContainerStyles = window.innerWidth < 600? smallerScreenStyles : containerStyles;
  
  // Use updatedContainerStyles in your component
  
  return (
    <div>
      {/* <h1>Hello monsterlessons</h1> */}
      <div style={updatedContainerStyles}>
        <ImageSlider slides={slides} />
      </div>
    </div>
  );
};

export default App;